import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import * as Chart from 'chart.js';
import * as moment from 'moment';
import { LoggerService } from 'src/app/common/services/logger.service';

declare module 'chart.js' {
  interface ChartTooltipModel {
    title: string[];
    body: ChartTooltipModelBody[];
    labelColors: ChartTooltipLabelColor[];
  }
}

@Component({
  selector: 'app-horizontal-chart',
  templateUrl: './horizontal-chart.component.html',
  styleUrls: ['./horizontal-chart.component.scss']
})
export class HorizontalChartComponent implements OnInit, OnChanges {
  @Input() holdStocks: any;
  @Input() sellStocks: any;
  @Input() startDate: any;
  chartData: any;
  selectedStockName: any;
  public canvas: any;
  public ctx: any;
  public labels: any = ['2014', '2015', '2016', '2017', '2018', '2019', '2020', '2021'];
  chart: any;
  @Input() inputData: any[] = [];
  public dropDownList: any[] = [];
  public barsArr: any[] = [];
  public selectedStock: any;
  private chartColors: any = {
    red: 'rgb(255, 99, 132)',
    orange: 'rgb(255, 159, 64)',
    yellow: 'rgb(255, 205, 86)',
    green: 'rgb(75, 192, 192)',
    blue: 'rgb(54, 162, 235)',
    purple: 'rgb(153, 102, 255)',
    grey: 'rgb(231,233,237)'
  };
  constructor() {}

  // uploadedDate : any;
  // @Input() chartData:any;
  // @Input() datesData:any;
  // @Input() pouchName :any;
  // @Input() indexName :any;
  // @Input() minIndex :any;
  // @Input() maxIndex :any;
  // @Input() duration : any;

  shortMonthNames: any = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  ngOnInit(): void {
    // this.uploadedDate = localStorage.getItem('stock_price_uploaded_date');
    // this.uploadedDate = new Date(this.uploadedDate);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('inputData')) {
      this.createDropDownList();
      this.selectedStock = this.dropDownList[0];
      this.selectedStockName = this.selectedStock.stock_name;
      this.createBars(this.selectedStock.stock_code);
      this.configChart();
      this.createLineChart('myChart');
    }
  }

  private createDropDownList() {
    // this.dropDownList = this.inputData.filter((item:any)=>  this.dropDownList.find((option:any)=> option.stock_code === item.stock_code) === undefined);
    this.inputData.filter((item) => {
      var i = this.dropDownList.findIndex((x: any) => x.stock_code == item.stock_code);
      if (i <= -1) {
        this.dropDownList.push(item);
      }
      return null;
    });
  }
  private configChart() {
    Chart.defaults.timeline = Chart.defaults.horizontalBar;
    Chart.controllers.timeline = Chart.controllers.horizontalBar.extend({
      initialize: function () {
        return Chart.controllers.bar.prototype.initialize.apply(this, arguments);
      }
    });
    Chart.pluginService.register({
      beforeInit: function (chart) {
        if (chart.config.type === 'timeline') {
          var config: any = chart.config;

          var data = config.data.datasets[0].data[0];
          config.data.datasets[0].defaultColor = 'red';
          var min = new Date(
            new Date(config.options.scales.xAxes[0].min).getFullYear(),
            new Date(config.options.scales.xAxes[0].min).getMonth(),
            new Date(config.options.scales.xAxes[0].min).getDate()
          );
          var max = new Date(
            new Date(config.options.scales.xAxes[0].max).getFullYear(),
            new Date(config.options.scales.xAxes[0].max).getMonth(),
            new Date(config.options.scales.xAxes[0].max).getDate()
          );
          function toDate(date: any, index: any) {
            var date: any = new Date(date);

            let Month = date.toLocaleString('default', { month: 'short' });
            let Year = date.getFullYear();
            return Month + ' ' + Year;
          }

          config.options.scales.xAxes[0].ticks.callback = toDate;
          config.options.scales.xAxes[0].ticks.min = min;
          config.options.scales.xAxes[0].ticks.max = max;
          config.options.scales.xAxes[0].ticks.fixedStepSize = 1000 * 60 * 60;
          config.options.scales.xAxes[0].ticks.minRotation = 0;
        }
      }
    });
  }
  removeData(chart: any) {
    // chart.data.labels = [];
    // chart.data.datasets = [];
    // chart.update();
  }
  public externalTooltipHandler = (context: any) => {};
  private createLineChart(chartId: string) {
    let objConfig: any = {
      type: 'timeline',
      data: {
        labels: ['', '', '', '', '', ''],
        datasets: [
          {
            backgroundColor: () => {
              let colors: any = [];
              for (let status of this.chartData) {
                if (status.isSold) {
                  colors.push(this.chartColors.grey);
                } else {
                  colors.push(this.chartColors.blue);
                }
              }
              return colors;
            },
            data: this.createDataArray(this.chartData)
          },
          {
            label: 'Current Holding ',
            backgroundColor: this.chartColors.blue
          },
          {
            label: 'Rebalanced Stocks',
            backgroundColor: this.chartColors.grey
          }
        ]
      },
      options: {
        legend: {
          labels: {
            filter: function (item: any, chart: any) {
              return item.datasetIndex !== 0;
            }
          }
        },
        tooltips: {
          enabled: false,
          custom: (tooltipModel: any) => {
            const shortMonthNames: any = [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ];
            // Tooltip Element
            var tooltipEl = document.getElementById('chartjs-tooltip');
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<div></div>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            // 
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = '0';
              return;
            }

            const getBody = (bodyItem: { lines: any }) => {
              return bodyItem.lines;
            };

            // Set Text

            if (tooltipModel.body) {
              var titleLines = tooltipModel.title || [];
              var bodyLines = tooltipModel.body.map(getBody);
              var data: any = this.chartData;

              function getPrice(val: any) {
                let barObj: any;
                for (let obj of data) {
                  if (new Date(obj.purchaseDate).getTime() === val) {
                    barObj = obj;
                  }
                }

                return barObj;
              }

              var innerHtml = `<div style='width 300px' class='container-fluid p-2'>`;

              bodyLines.forEach(function (body: any, i: any) {
                var label = JSON.parse(body[0]);
                var colors = tooltipModel.labelColors[i];

                innerHtml += `<div class='row'>
                                 Purchase -${new Date(label[0]).getDate()}-${
                                   shortMonthNames[new Date(label[0]).getMonth()]
                                 }- ${new Date(label[0]).getFullYear()} @ ${
                                   getPrice(label[0]).purchasePrice
                                 }
                              </div>  
                              
                              <div class='row'>
                              Sale -${new Date(label[1]).getDate()}-${
                                shortMonthNames[new Date(label[1]).getMonth()]
                              }- ${new Date(label[0]).getFullYear()} @ ${
                                getPrice(label[0]).salePrice
                              }
                           </div>   
                `;
                if (getPrice(label[0]).price_change < 0) {
                  innerHtml += `<div class='row'>
                   <div class = 'col-1'  style=' margin: auto 0 ; padding:0'> 
                   <div
                  style='
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 10px 5px 0 5px;
                  border-color: #ff0000 transparent transparent transparent;
                  '
                  >
                  </div>
                   </div>
                   <div class = 'col-3'><strong>${Math.abs(getPrice(label[0]).price_change).toFixed(
                     2
                   )}%</strong> </div>
                  </div`;
                } else {
                  innerHtml += `<div class='row'>
                  <div class = 'col-1' style=' margin: auto 0 ; padding:0' > 
                  <div style ='
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 0 5px 10px 5px;
                  border-color: transparent transparent #22ff00 transparent;

                  '></div>
                  </div>
                  <div class = 'col-3'><strong>${Math.abs(getPrice(label[0]).price_change).toFixed(
                    2
                  )}%</strong></div>
                 </div>`;
                }
              });

              innerHtml += '</div>';

              var tableRoot = tooltipEl.querySelector('div');
              tableRoot!.innerHTML = innerHtml;
              var position = document.getElementById(chartId)?.getBoundingClientRect();
              tooltipEl.style.zIndex = '3';
              tooltipEl.style.opacity = '1';
              tooltipEl.style.background = 'white';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position!.left + tooltipModel.caretX - 200 + 'px';
              tooltipEl.style.top = position!.top + window.pageYOffset + tooltipModel.caretY + 'px';
              tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
              tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
              tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
              tooltipEl.style.padding = 10 + 'px ' + 20 + 'px';
              tooltipEl.style.boxShadow = '0px 0px 10px rgba(0, 0, 0, 0.08)';
              tooltipEl.style.pointerEvents = 'none';
            }
          }
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              min: new Date(this.startDate).getTime(),
              max: new Date().getTime()
            }
          ],
          yAxes: [
            {
              stacked: true,
              categoryPercentage: 0.5,
              barPercentage: 1
            }
          ]
        }
      }
    };
    if (this.chart) {
      this.canvas = null;
      this.ctx = null;
      this.chart.destroy();
    }
    this.canvas = document.getElementById(chartId);
    this.ctx = this.canvas.getContext('2d');
    this.chart = new Chart(this.ctx, objConfig);
  }

  public onChangeSelection(event: any) {
    this.createBars(event.value.stock_code);
    this.configChart();
    this.createLineChart('myChart');
  }

  private createBars(stockCode: any) {
    this.createChartData(stockCode);
  }

  public findMin() {
    let dates: any = [];
    for (let date of this.inputData) {
      dates.push(new Date(date.buy_date).getTime());
    }
    let min = Math.min(...dates);
    return min;
  }
  public createChartData(val: any) {
    let chartData = [];

    for (let sellStock of this.sellStocks) {
      if (sellStock.stock_code === val) {
        chartData.push({
          purchaseDate: sellStock.buy_date,
          saledate: sellStock.sell_date,
          isSold: true,
          purchasePrice: sellStock.buy_rate,
          salePrice: sellStock.sell_rate,
          price_change: sellStock.price_change_realized
        });
      }
    }
    for (let holdStock of this.holdStocks) {
      if (holdStock.stock_code === val) {
        chartData.push({
          purchaseDate: holdStock.buy_date,
          saledate: holdStock.sell_date,
          isSold: false,
          purchasePrice: holdStock.buy_rate,
          salePrice: holdStock.close_price,
          price_change: holdStock.price_change_unrealized
        });
      }
    }
    this.chartData = chartData;
  }

  private createDataArray(chartData: any) {
    let dataArr: any[] = [];

    for (let item of chartData) {
      let itemArr: any[] = [
        new Date(item.purchaseDate).getTime(),
        item.saledate ? new Date(item.saledate).getTime() : new Date().getTime(),
        item.purchasePrice,
        item.sellPrice
      ];
      dataArr.push(itemArr);
    }

    return dataArr;
  }
}
