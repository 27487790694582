import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaderResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class RedirectInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(event => {
                if (event instanceof HttpHeaderResponse) {
                    console.log(event.headers.get('Location'));
                    if (event.status === 303) {
                        const redirectUrl = event.headers.get('Location');
                        console.log(redirectUrl); 
                    }
                }
            })
        );
    }
}