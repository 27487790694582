import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';

import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken && refreshToken !== '') {
      sessionStorage.removeItem('redirect');
      return true;
    }
  
    const currentUrl = window.location.pathname;

    if (currentUrl.includes('/investment/map-subscriber/')) {
      sessionStorage.setItem('redirect', currentUrl);
    }
    this.router.navigateByUrl('auth/login');
    return false;
  }
}
