import { P } from '@angular/cdk/keycodes';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

import { Observable } from 'rxjs/internal/Observable';

import { CommonDialogComponent } from 'src/app/common-dialog/common-dialog.component';
import { DeletePopupComponent } from 'src/app/common/components/delete-popup/delete-popup.component';
import { UtilsService } from 'src/app/common/services/utils.service';
import { Load } from 'src/app/modules/investment-pouches/store/actions/pouch.action';
import { PouchState, selectPouchState } from 'src/app/modules/investment-pouches/store/pouch.state';

import { TEXTS } from '../../../common/constants/text-constants';
import { PublicService } from '../../public/services/public.service';
import { PublicFacadeService } from '../../public/state/public.facade.services';
import { MapSubscriberPopupComponent } from '../map-subscriber-popup/map-subscriber-popup.component';
import { PouchService } from '../services/pouch.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnChanges {
  @Input() userType: any;
  @Input() sortId: any;
  @Input() search: any = '';
  uploadDate: any;
  Object = Object;
  limit = 10;
  getState: Observable<any>;
  isUserLoggedin: boolean = false;
  defaultBody: any;
  throttle = 100;
  scrollDistance = 1;
  scrollUpDistance = 2;
  @Input() pouches: any[];
  @Input() showGhost: boolean;
  @Input() listView: any;
  @Input() showTotalSub: boolean = true;
  @Input() public: boolean;
  @Output() onPouchClick = new EventEmitter();
  @Input() annualizedReturns: any;
  min_investment: number;
  //
  loadingGhost: boolean = true;
  tabelGhost: boolean;

  //
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private publicService: PublicService,
    public facadeService: PublicFacadeService,
    public pouchService: PouchService,
    private store: Store<PouchState>,
    private utilsService: UtilsService
  ) {
    if (localStorage.getItem('user')) {
      this.isUserLoggedin = true;
      this.getState = this.store.select(selectPouchState);
    } else {
      this.isUserLoggedin = false;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.search.length >= 3) {
      this.loadingGhost = true;
      this.pouches = [];
      this.pouches = changes?.pouches?.currentValue;
      if (this.pouches) {
        this.loadingGhost = false;
        this.tabelGhost = false;
      }
    } else if (this.search.length === 0) {
      this.loadingGhost = true;
      this.pouches = [];
      this.pouches = changes?.pouches?.currentValue;
      if (this.pouches) {
        this.loadingGhost = false;
        this.tabelGhost = false;
      }
    }
  }

  ngOnInit(): void {
    this.uploadDate = localStorage.getItem('stock_price_uploaded_date');
    if (this.listView === false) {
      this.facadeService.getAnnualizedfromStore({
        current_date: this.uploadDate.split('T')[0]
      });
    }

    this.facadeService.LoadAnnualizedfromState$.subscribe((res) => {
      this.annualizedReturns = res;
    });
    if (!this.isUserLoggedin) {
      this.facadeService.LoadPouchListfromState$.subscribe((res: any) => {
        this.pouches = [...res];
      });
      if (this.pouches?.length == 0) {
        this.facadeService.getPouchListfromStore(this.uploadDate);
      }
    } else {
      if (this.userType == 'public') {
        this.defaultBody = {
          sort: 2,
          subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
          current_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
          limit: this.limit
        };

        this.store.dispatch(new Load(this.defaultBody));

        this.getState.subscribe((state) => {
          let statePouches = state.pouches;

          if (statePouches && !(statePouches.error && statePouches.error.message != '')) {
            this.pouches = [...statePouches];
          }
        });
      }
    }
  }

  loadPouchDetails() {
    if (this.uploadDate) {
      this.facadeService.getPouchListfromStore({
        current_date: this.uploadDate,
        limit: this.limit,
        sort: -13
      });

      this.facadeService.LoadPouchListfromState$.subscribe((list: any) => {
        this.pouches = list;
      });
      
      
    }
  }

  mapSubscriber() {
    this.router.navigateByUrl('investment-pouches/map-subscriber');
  }
  goPerformance(item: any) {
    const host = window.location.host;
    const subdomain = host.split('.')[0];

    if (subdomain === 'richmee' ||subdomain=='kandkfinserv' || subdomain=='nirjainvestments') {
      if (this.userType === 'public') {
        this.router.navigateByUrl(`/auth/register`);
        return;
      } else {
        this.router.navigateByUrl(`investment/map-subscriber/${item.id}`);
        return;
      }
      this.publicService.getReferrerLink({ referrer_id: 'richmee' }).subscribe((res: any) => {
        if (res) {
          let redirectedLink = res.sv_link;
          window.location.href = redirectedLink;
        }
      });
      return;
    }

    if (this.userType === 'public') {
      this.router.navigateByUrl(`public/pouchlist-details/${item.id}`);
    } else {
      this.router.navigateByUrl(`investment/map-subscriber/${item.id}`);
    }
  }
  mapSubscriberpopup(item: any) {
    this.getminInvestmentAmount(item);
  }
  getAnnualizedValue(benchMarkIndex: any) {
    let returns = this.annualizedReturns.find(
      (element: any) => element.index_name === benchMarkIndex
    );

    return (Number(returns?.change_perc || 0) * 100).toFixed(2) || 0;
  }

  editPouch(item: any) {
    this.router.navigateByUrl(`investment/edit-pouch/${item.id}`);
  }

  onScrollDown() {
    this.limit = this.limit + 10;
    this.tabelGhost = true;
    if (!this.isUserLoggedin) {
      if (this.uploadDate) {
        this.facadeService.getPouchListfromStore({
          current_date: this.uploadDate,
          limit: this.limit,
          sort: this.sortId,
          pouch_name: this.search
        });
      }
    } else {
      this.defaultBody = {
        sort: this.sortId,
        subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
        current_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
        limit: this.limit,
        pouch_name: this.search
      };
      this.store.dispatch(new Load(this.defaultBody));
      // this.tabelGhost=false;
    }
  }

  onUp() {}

  getminInvestmentAmount(item: any) {
    let data: any = {
      pouch_id: item.id,
      stock_price_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0]
    };
    this.pouchService.getPouchStocksReturns(data).subscribe((response: any) => {
      let returns: any = response[0].Results[0].return_data?.pouch_stocks_returns;
      let sumArr: number[] = [];
      if (returns) {
        for (let data of returns) {
          if (!data.sell_date) {
            sumArr.push(data.close_price * data.stock_tf_count);
          }
        }
        let sum = 0;
        for (let i = 0; i < sumArr.length; i++) {
          sum += sumArr[i];
        }
        this.min_investment = Number(sum.toFixed(2));
      }
      this.dialog.open(MapSubscriberPopupComponent, {
        data: {
          pouch_id: item.id,
          min_investment: this.min_investment || 0,
          pouch: item
        }
      });
    });
  }
  showDelete(pouch: any) {
    //check user is admin , and check user id is same as created id , subscriber 0
    let userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    if (pouch.total_subscribers > 0 && pouch.inactive_subscriber > 0) {
      return false;
    }
    if (
      pouch.total_subscribers == 0 &&
      pouch.inactive_subscriber == 0 &&
      userInfo.id === pouch.created_by &&
      userInfo.attributes.role === 'admin'
    ) {
      return true;
    }
    if (userInfo.attributes.superadmin == 'Y') {
      return true;
    }
    if (userInfo.attributes.referrer_id === pouch.created_by) {
      return true;
    }

    return false;
  }
  showEdit(pouch: any) {
    //check user is owner, admin or superadmin
    let userInfo = JSON.parse(localStorage.getItem('user') || '{}');

    if (
      userInfo.id === pouch.created_by || // Owner of pouch
      userInfo.attributes.role === 'admin' || // Admin
      userInfo.attributes.superadmin == 'Y' // Super Admin
    ) {
      return true;
    }

    return false;
  }
  checkacess(pouch: any) {
    // let userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    //
    //
    //
    //
    // console.table([  userInfo.id === pouch.created_by ,
    //   userInfo.attributes.role === 'admin' ,
    //   userInfo.attributes.superadmin == 'Y'])
  }

  deletePouch(pouch: any) {
    const dialogRef: any = this.utilsService.openDialog(
      DeletePopupComponent,
      { message: TEXTS.DELETE_TEXT },
      500
    );
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res.type === 'yes') {
        this.pouchService
          .deletePouch({
            pouch_id: [pouch.id]
          })
          .subscribe((res: any) => {
            const dialog: any = this.dialog.open(CommonDialogComponent, {
              data: {
                message: 'Pouch deleted successfully.',
                title: 'Success',
                status: 'Success'
              }
            });
            this.defaultBody = {
              sort: 2,
              subscriber_id: JSON.parse(localStorage.getItem('user') || '{}').id,
              current_date: localStorage.getItem('stock_price_uploaded_date')?.split('T')[0],
              limit: this.limit
            };
            setTimeout(() => {
              this.store.dispatch(new Load(this.defaultBody));
              dialog.close();
            }, 1000);
          });
      }
    });
  }
  gotoPouchVisibility(item: any) {
    let selectedPouch = item;
    this.router.navigateByUrl(`/investment/pouch-visibility/${selectedPouch.id}`);
  }
  filterPouches(pouchArr: any) {
    let user: any = localStorage.getItem('user');
    if (user) {
      user = JSON.parse(user);
    }
    if (user?.attributes?.role === 'admin') {
      return pouchArr;
    }
    pouchArr = pouchArr.filter(
      (pouch: any) => !pouch.stop_sub || (pouch.subscribed === 'Y' && pouch.stop_sub)
    );
    return pouchArr;
  }
  variable(item:any) {
    this.router.navigateByUrl(`investment/variable-pricing/${item.id}`);
  }
}
