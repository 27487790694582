<div class="riskProfileContainer">
  <div class="text-center mt-30">
    <img src="../../../../assets/images/icons/warning.svg" style="width: 50px" />
  </div>
  <div class="f-16 fw-400 text-darkgrey text-center mt-30">
    <div>
      Your current risk profile does not match the risk profile of the selected pouch: <strong> {{ data[2] }}</strong>
    </div>
  </div>

  <div class="f-16 fw-400 text-darkgrey mt-20 text-center">
    <div  class="fw-600">You can update your risk profile to proceed for this pouch or subscribe to another pouch that align with your existing risk profile.</div>

  </div>
  <div class="row justify-center mt-20">
    <div class="col-4 col-sm-3" style="margin-left: 20px">
      <button class="theme-btn border-btn mb-20 f-12 w-100" (click)="dialogRef.close(false)">
        Cancel
      </button>
    </div>
    <div class="col-4 col-sm-3">
      <button class="theme-btn primary-btn mb-20 f-12 w-100" (click)="dialogRef.close(true)">
        Update Profile
      </button>
    </div>
  </div>
</div>
