<div [hidden]="hideElement" class="footer-wrapper f-12">
  <div class="container">
    <div class="row">
      <div class="col-md-6">All rights reserved. Lamron Analysts Pvt Ltd.</div>
      <div class="col-md-6 text-right">Privacy Policy | Terms & Conditions</div>
    </div>
  </div>
</div>
<!-- <div class="footer" *ngIf="footerCheck">
    <app-public-footer></app-public-footer>
</div> -->
