<ng-container *ngIf="routeShow && rp && closeNot">
  <div class="toast-wrapper">
    <div class="d-flex notification">
      <div class="not-img">
        <img src="../../assets/images/icons/warning.svg" />
      </div>
      <div class="text-darkgrey f-12 fw-400 ml-10">
        You have not updated your Risk Profile yet. To update the same, please
        <span style="color: #065dbc" (click)="go('risk')" class="cursor"
          >answer these basic questions.</span
        >
      </div>
    </div>
    <div class="icon cursor" (click)="goto('firstClick')">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="subdomain !=='wealthy' && subdomain!=='bajajbroking'">
  <ng-container *ngIf="routeShow && kyc && closeNot1">
    <div class="toast-wrapper">
      <div class="d-flex notification">
        <div class="not-img">
          <img src="../../assets/images/icons/warning.svg" />
        </div>
        <div class="text-darkgrey f-12 fw-400 ml-10">
          You have not updated your KYC yet. To update the same, please
          <span style="color: #065dbc" (click)="go('kyc')" class="cursor"
            >upload your documents.</span
          >
        </div>
      </div>
      <div class="icon cursor" (click)="goto('firstClick2')">
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </ng-container>
</ng-container>
<ng-container *ngIf="routeShow && !rp && rp_exp && closeNot2">
  <div class="toast-wrapper">
    <div class="d-flex notification">
      <div class="not-img">
        <img src="../../assets/images/icons/warning.svg" />
      </div>
      <div class="text-darkgrey f-12 fw-400 ml-10">
        Your Risk Profile has Expired.Please
        <span style="color: #065dbc" (click)="go('risk')" class="cursor">update.</span>
      </div>
    </div>
    <div class="icon cursor" (click)="goto('firstClick3')">
      <mat-icon>close</mat-icon>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="subdomain !=='wealthy' && subdomain!=='bajajbroking'">
  <ng-container *ngIf="routeShow && !kyc && kyc_exp && closeNot3">
    <div class="toast-wrapper">
      <div class="d-flex notification">
        <div class="not-img">
          <img src="../../assets/images/icons/warning.svg" />
        </div>
        <div class="text-darkgrey f-12 fw-400 ml-10">
          Your KYC has Expired.Please
          <span style="color: #065dbc" (click)="go('kyc')" class="cursor">update.</span>
        </div>
      </div>
      <div class="icon cursor" (click)="goto('firstClick4')">
        <mat-icon>close</mat-icon>
      </div>
    </div>
  </ng-container>
</ng-container>

