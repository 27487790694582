import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/core/auth.guard';
import { NavigationComponent } from 'src/app/modules/navigation/navigation.component';
import { LandingPageComponent } from './modules/public/components/landing-page/landing-page.component';
import { BajajfinservsecuritiesLandingPageComponent } from './modules/public/partners/bajajfinservsecurities-landing-page/bajajfinservsecurities-landing-page.component';
import { RichmeeLandingPageComponent } from './modules/public/partners/richmee-landing-page/richmee-landing-page.component';
import { WealthyLandingPageComponent } from './modules/public/partners/wealthy-landing-page/wealthy-landing-page.component';
import { DhanhqLandingPageComponent } from './modules/public/partners/dhanhq-landing-page/dhanhq-landing-page.component';
import { KandkfinservLandingPageComponent } from './modules/public/partners/kandkfinserv-landing-page/kandkfinserv-landing-page.component';
import { NirjainvestmentsLandingPageComponent } from './modules/public/partners/nirjainvestments-landing-page/nirjainvestments-landing-page.component';
import { RedirectComponent } from './common/redirect/redirect.component';

const host = window.location.host;
const subdomain = host.split('.')[0];
const Component: any = {
  richmee: RichmeeLandingPageComponent,
  bajajbroking: BajajfinservsecuritiesLandingPageComponent,
  wealthy: WealthyLandingPageComponent,
  dhan: DhanhqLandingPageComponent,
  kandkfinserv:KandkfinservLandingPageComponent,
  default: LandingPageComponent,
  nirjainvestments:NirjainvestmentsLandingPageComponent
};
const routes: Routes = [
  {
    path: '',
    redirectTo: subdomain && Component[subdomain]?undefined:'/public/landing-page',
    pathMatch:  subdomain && Component[subdomain]?undefined:'full',
    component:subdomain && Component[subdomain]?Component[subdomain]:undefined
  },

  {
    path: 'subscription-plan',
    loadChildren: () =>
      import('./subscription/subscription.module').then((m) => m.SubscriptionModule)
  },
  {
    path: 'agreement',
    loadChildren: () => import('./agreement/agreement.module').then((m) => m.AgreementModule)
  },
  {
    path: 'agreement-subscribe',
    loadChildren: () => import('./agreement-subscribe/agreement-subscribe.module').then((m) => m.AgreementSubscribeModule)
  },

  {
    path: 'pouch-creation',
    loadChildren: () =>
      import('./pouch-creation/pouch-creation.module').then((m) => m.PouchCreationModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then((m) => m.ProfileModule)
  },

  {
    path: 'login',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: 'portfolio',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/investment/investment.module').then((m) => m.InvestmentModule)
  },
  {
    path: 'investment',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../app/modules/investment-pouches/investment-pouches.module').then(
        (m) => m.InvestmentPouchesModule
      )
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/modules/reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'more',
    canActivate: [AuthGuard],
    loadChildren: () => import('../app/modules/more/more.module').then((m) => m.MoreModule)
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('../app/modules/onbording/onbording.module').then((m) => m.OnbordingModule)
  },
  {
    path: 'auth',
    redirectTo: ['wealthy','bajajbroking'].includes(subdomain)?'/':undefined,
    loadChildren: () => import('../app/modules/auth/auth.module').then((m) => m.AuthModule)
  },

  {
    path: 'public',
    loadChildren: () => import('../app/modules/public/public.module').then((m) => m.PublicModule)
  },

  {
    path: 'smart-value',
    component: NavigationComponent,
    loadChildren: () =>
      import('./modules/navigation/navigation.module').then((m) => m.NavigationModule)
  },
  {
    path: 'redirect/map-subscriber/:id',
    component: RedirectComponent,
  },
  {
    path: '**',
    redirectTo: subdomain && Component[subdomain]?'/':'/public/landing-page',
    pathMatch:'full'
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
