import { SharedService } from 'src/app/common/services/shared.service';
import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';

import { PublicFacadeService } from 'src/app/modules/public/state/public.facade.services';

import { AuthService } from '../../modules/auth/services/auth.service';
import { PublicService } from 'src/app/modules/public/services/public.service';
const subdomains=['richmee',
  'bajajbroking',
  'wealthy',
  'dhan','kandkfinserv','nirjainvestments']
export function appInitializer(authService: AuthService, facadeService: PublicFacadeService,publicService:PublicService) {
  const refresh_token: any = localStorage.getItem('refreshToken');
  const id: any = localStorage.getItem('id');

  const url: any = window.location.href;
  return () =>
    new Promise((resolve: any) => {
      /*authService.getTokens(refresh_token).subscribe(response => {
            authService.setTokens(response);

            if (url) {
                resolve(true);
            }
        })*/
            sessionStorage.removeItem('brokers')
            const host = window.location.host;
            const subdomain = host.split('.')[0];
            if (subdomains.includes(subdomain)) {
            publicService
                .get_referrer_subdomain({
                  referrer_id: subdomain
                })
                .subscribe((res: any) => {

                  sessionStorage.setItem('brokers', JSON.stringify({
                 brokers:res[0].referrers[0].brokers
               }))
                });
            } else {
              sessionStorage.removeItem('brokers')
            }
      facadeService.setUploadDateToStore();
      if (refresh_token && refresh_token != '' && refresh_token != 'undefined') {
        authService
          .getTokens(refresh_token, id)
          .subscribe((response) => {
            // const token = {
            //     IdToken: response.id_token,
            //     RefreshToken: response.RefreshToken
            // }
            authService.setTokens(response);
            authService.startTokenUpdateTimer(response.expires_in / 60 - 5);
            if (url) {
              resolve(true);
            }
          })
          .add(resolve);
      } else {
        setTimeout(() => {
          resolve('foo');
        }, 300);
      }
    });
}
