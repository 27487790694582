<div class="row popup-container">
  <div class="row f-16 fw-600 l-24 border-bottom pb-10">Execute Trades</div>
  <ng-container *ngIf="data?.data?.length > 0">
    <div class="row" style="padding: 10px 0;">
      <div class="col-md-4" style="font-weight: 600;"> Stock Name</div>
      <div class="col-md-1"></div>
      <div class="col-md-2">LTP</div>

      <div class="col-md-2"></div>
      <div class="col-md-3" style="font-weight: 600;text-align: center;" >Qty</div>
    </div>
    <div class="innerwrapper">

      <div class="row mt-20 addedrow" *ngFor="let item of (stockData|nonZeroTrades:review_data); let i = index">

        <div class="col-md-4" *ngIf="item.txn_status !== 'PLACED'||item.txn_status === 'BUY'">
          {{ item.stock_name }}
        </div>
        <div class="col-md-1" *ngIf="item.txn_status !== 'PLACED'||item.txn_status === 'BUY'"></div>
        <div class="col-md-2" style="padding: 0;">
          <ng-container *ngIf="show_ghost">
            <div class="ghoast_container">
   <span class="rsFontFamily">&#8377;</span> <div class="ghost_loading" style="height: 20px;width:70%"></div>
  </div>
 </ng-container>
 <ng-container *ngIf="!show_ghost">
          <span class="rsFontFamily">&#8377;</span> {{item.close_price|numFormat:'thousand':'2'}}
        
        </ng-container>
        </div>
        <div class="col-md-2">
          <button
            mat-button
            class="theme-btn primary-btn w-100 buyBtn"
            [ngClass]="item.txn_type === 'BUY' ? 'greenClass' : 'redClass'">
            {{ item.txn_type }}
          </button>
        </div>
        <!-- <div class="col-md-2"></div> -->
        <div class="col-md-3 qty">

          <form style="margin-left: 5px; display: flex">
            <button *ngIf="!review_data" (click)="decrement(i)" class="plusbtn">-</button>
            <input
              matInput
              [disabled]="review_data"
              type="number"
              [value]="item.stock_qty"
              (input)="onInputChange($event, i)"
              style="width: 100%;" />
            <button *ngIf="!review_data"  (click)="increment(i)" class="plusbtn">+</button>
          </form>
        </div>
      </div>
    </div>
    <div class="row mt-20 border-top">
      <ng-container *ngIf="data.from !=='smart-stocks'">
        <div class="col-12 mb-10">
          <!-- <div class="error_style mt-10" *ngIf="(Number(stockData|currentTradeValue) + Number(data.current_investment))> Number(data.invesment_amount)">Total traded value cannot be greater than investment amount - you can topup your investment by clicking the update link </div> -->
          <ng-container *ngIf="!((Number(stockData|currentTradeValue)) <= Number(initialCurrentTradeValue))">
          <div class="error_style mt-10" *ngIf="((Number(current_trade_value) + Number((stockData|currentTradeValue)))) > Number(data.invesment_amount)">Trade Value Exceeds Investment Limit</div>
          </ng-container>
          <div class="error_style mt-10" *ngIf="((Number(stockData|currentTradeValue))> Number(broker_balance))">Current trade value cannot be greater than broker balance </div>

        </div>
      </ng-container>
      <ng-container *ngIf="data.from =='smart-stocks'">
        <div class="col-12 mb-10">
          <ng-container *ngIf="!((Number(stockData|currentTradeValue)) <= Number(initialCurrentTradeValue))">
          <!-- <div class="error_style mt-10" *ngIf="(Number(stockData|currentTradeValue) + Number(data.current_investment))> Number(data.invesment_amount)">Total traded value cannot be greater than investment amount - you can topup your investment by clicking the update link </div> -->
          <div class="error_style mt-10" *ngIf="(Number((stockData|currentTradeValue))) > Number(data.invesment_amount)">Trade Value Exceeds Investment Limit</div>
       </ng-container>
       <div class="error_style mt-10" *ngIf="(Number(stockData|currentTradeValue))> Number(broker_balance)">Current trade value cannot be greater than broker balance </div>

        </div>
      </ng-container>
      <div class="col-3 f-12">
        <div>Current Trade Value</div>
        <ng-container *ngIf="show_ghost">
                   <div class="ghoast_container">
          <span class="rsFontFamily">&#8377;</span> <div class="ghost_loading" style="height: 20px;width:70%"></div>
         </div>
        </ng-container>
        <ng-container *ngIf="!show_ghost">

          <div><span class="rsFontFamily">&#8377;</span> {{(stockData |currentTradeValue) |numFormat:'thousand':'2'}}  <img  (click)="fetchLatestTrade()" style="cursor: pointer;" *ngIf="!review_data && !data.isPlaced" src="../../../../assets/images/icons/refreshIcon.svg" alt=""></div>
        </ng-container>
      </div>
      <div class="col-3 f-12">
        <div>Broker Balance</div>
        <ng-container *ngIf="show_ghost">
                   <div class="ghoast_container">
          <span class="rsFontFamily">&#8377;</span> <div class="ghost_loading" style="height: 20px;width:70%"></div>
         </div>
        </ng-container>
        <ng-container *ngIf="!show_ghost">
        <div><span class="rsFontFamily">&#8377;</span> {{broker_balance|numFormat:'thousand':'2'}} </div>
        </ng-container>
      </div>
      <div class="col-3 f-12">
        <div>Subscriber Investment
           <!-- past trade value <span class="rsFontFamily">&#8377;</span>{{data.current_investment | number: '1.0-0'}} -->
          </div>
          <ng-container *ngIf="show_ghost">
                     <div class="ghoast_container">
          <span class="rsFontFamily">&#8377;</span> <div class="ghost_loading" style="height: 20px;width:70%"></div>
         </div>
          </ng-container>
          <ng-container *ngIf="!show_ghost">
          <div><span class="rsFontFamily">&#8377;</span> {{data.invesment_amount |numFormat:'thousand':'2'}}
            <span *ngIf="data.from !=='smart-stocks'" [ngClass]="data.isAnyPlaced?'hyperLinkStyle--disabled':'hyperLinkStyle'" (click)="data.isAnyPlaced?null:closeAndOpenUpdateAmnt()"  [matTooltip]="data.isAnyPlaced?tooltipText:''"
          >(update)</span>

          </div>
          </ng-container>
      </div>
      <div class="col-3 f-12" *ngIf="data.from !=='smart-stocks'">
  Past Trade Value
  <ng-container *ngIf="show_ghost">
             <div class="ghoast_container">
          <span class="rsFontFamily">&#8377;</span> <div class="ghost_loading" style="height: 20px;width:70%"></div>
         </div>
  </ng-container>
  <ng-container *ngIf="!show_ghost">
  <div><span class="rsFontFamily">&#8377;</span> {{current_trade_value |numFormat:'thousand':'2'}}</div>
  </ng-container>
      </div>
    </div>
    <div class="col-12 justify-end  button-container d-flex">
      <div class="ml-20" *ngIf="!review_data">
       <ng-container *ngIf="data.from !=='smart-stocks'">
        <button

        Copy code
        [disabled]="
          !(Number(stockData|currentTradeValue) <= Number(initialCurrentTradeValue) ||
          (Number(current_trade_value) + Number(stockData|currentTradeValue)) <= Number(data.invesment_amount)) ||
          Number(stockData|currentTradeValue) > Number(broker_balance)
        "
        mat-button
        class="theme-btn primary-btn f-12 fw-600 mt-10"
        style="width: 115px;color: white!important;"
        (click)="review_data = !review_data"
        >
       Review Orders
      </button>
       </ng-container>
       <ng-container *ngIf="data.from ==='smart-stocks'">
        <button

Copy code
[disabled]="
  !(Number(stockData|currentTradeValue) <= Number(initialCurrentTradeValue) ||
  (Number(current_trade_value) + Number(stockData|currentTradeValue)) <= Number(data.invesment_amount)) ||
  Number(stockData|currentTradeValue) > Number(broker_balance)
"
mat-button
        class="theme-btn primary-btn f-12 fw-600 mt-10"
        style="width: 115px;color: white!important;"
        (click)="review_data = !review_data"
        >
       Review Orders
      </button>
       </ng-container>
      </div>
      <div class="" *ngIf="review_data">
        <button (click)="goToReviewOrder()" mat-button class="theme-btn border-btn f-14 fw-500 mt-10">
          Edit Orders
        </button>
      </div>
      <div class="ml-20" *ngIf="review_data">
        <button
          mat-button
          class="theme-btn primary-btn f-12 fw-600 mt-10"
          style="width: 105px"
          (click)="save()">
          Place Orders
        </button>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!(data?.data?.length > 0)">
    <div class="innerwrapper" style="height: 150px!important;margin: auto;display: flex; justify-content: center;align-items: center;">
      There are no outstanding transactions at the moment.
    </div>
    <div class="col-12 justify-end mt-20 border-top button-container d-flex">
      <div class="">
        <button mat-dialog-close mat-button class="theme-btn border-btn f-14 fw-500 mt-10">
          Cancel
        </button>
      </div>
    </div>
  </ng-container>
</div>
